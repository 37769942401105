import { z } from "zod";
import { paginationSchema } from "./pagination";

export const getExternalEquipmentListParamsSchema = z.object({
  reference: z.string().or(z.number()),
}).and(paginationSchema);
export type GetExternalEquipmentListParamsSchema = z.infer<typeof getExternalEquipmentListParamsSchema>;

export const getExternalWOListParamsSchema = z.object({
  serialNumber: z.string().or(z.number()),
  reference: z.string().or(z.number()),
}).and(paginationSchema);
export type GetExternalWOListParamsSchema = z.infer<typeof getExternalWOListParamsSchema>;

export const externalEquipmentSchema = z.object({
  entityId: z.string(),
  name: z.string(),
  serialNumber: z.string(),
  uniqueSerial: z.string(),
});
export type ExternalEquipmentSchema = z.infer<typeof externalEquipmentSchema>;

export const externalWOSchema = z.object({
  documentNumber: z.number(),
  subject: z.string(),
  state: z.number(),
  statusCode: z.string(),
  dateOpened: z.string(),
  dateClosed: z.string().nullable(),
  customerPurchaseOrderNumber: z.string(),
  shipAddress: z.object({
    addressLine1: z.string(),
    addressLine2: z.string(),
    addressLine3: z.string(),
    addressLine4: z.string(),
    addressLine5: z.string(),
    city: z.string(),
    state: z.string(),
    zipCode: z.string(),
    country: z.string(),
  }),
});
export type ExternalWOSchema = z.infer<typeof externalWOSchema>;